
import Hero from "./Hero";
import Benefits from "./Benefits"
import Setup from "./Setup";
import Accordion from "./Accordin";
import Products from "./Products";
import Food from "./Food"
import Deploys from "./Deploys";
const Business = () => {

  return (
    <>
<Hero/>
<Benefits/>
<Accordion/>
<Setup/>
<Food/>
<Products/>
<Deploys/>
    </>)
}
export default Business;